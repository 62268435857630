import type { ITheme } from '@fluentui/react';
import * as React from 'react';

import type { IM365Theme } from '../customizations';

const { createContext } = React;

/**
 * Context interface that can be used to disable throwing on undefined color, or provide a custom callback.
 */
export interface IThrowOnUndefinedColorContext {
  /**
   * If true, will not throw an error if a color is undefined.
   * @default false
   */
  disableThrowOnUndefinedColor?: boolean;

  /**
   * Callback that is called when a color is undefined.
   */
  onThrowOnUndefinedColor?: (args: {
    desiredColor: string | undefined;
    desiredColorName: string;
    callingComponentName: string;
    theme?: ITheme | IM365Theme;
  }) => string | undefined;
}

/**
 * Context that can be used to disable throwing on undefined color, or provide a custom callback.
 */
export const ThrowOnUndefinedColorContext = createContext<IThrowOnUndefinedColorContext>(
  {},
);

/**
 * Function that checks if a color is defined, and if not, throws an error.
 * @param desiredColor the color to check
 * @param desiredColorName the semantic slot of the color
 * @param callingComponentName component that is requesting the color
 * @param context optional context to disable throwing or provide a custom callback
 */
export function throwOnUndefinedColor(
  desiredColor: string | undefined,
  desiredColorName: string,
  callingComponentName: string,
  options?: { context?: IThrowOnUndefinedColorContext; theme?: ITheme | IM365Theme },
): string {
  const { context, theme } = options ?? {};

  let fallbackColor = '';

  // If there is no color, throw an error, unless throwing is disabled through config.
  if (!desiredColor) {
    // If we have a custom callback, call it
    fallbackColor =
      context?.onThrowOnUndefinedColor?.({
        desiredColor,
        desiredColorName,
        callingComponentName,
        theme,
      }) ?? '';

    const warningString = `M365Theme error: It looks like the app tried to reference a theme color that didn't exist.\n
    Double check you're consuming a M365Theme (a super set of Fabric ITheme) and that it has assigned the extension slot.\n
    There's also a chance you've loaded multiple themes into the context. Run __packages__ in your web console and if you see multiple instances of OUIFR or Fluent, that might be the case.
    Undefined extension slot: ${desiredColorName}\n
    Calling component: ${callingComponentName}\n
    'For more information, please visit https://uifabric.visualstudio.com/iss/_git/m365-admin/?path=%2Fdocs%2FnextGenThemeSystem.md&version=GBmaster';`;

    if (!context?.disableThrowOnUndefinedColor) {
      throw new Error(warningString);
    } else if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
      /**
       * When we're in development and throwing is disabled, we want to warn the developer that they're using an undefined color and this could cause bugs
       */
      console.warn(warningString);
    }
  }

  // We need to return either a color or an empty string so we aren't returning undefined. desiredColor will only return undefined if someone opts out of throwing in which case we'll return empty vs undefined to avoid this issue in typings.
  return desiredColor ?? fallbackColor;
}
