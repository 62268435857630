import { classNamesFunction, getId } from '@fluentui/react';
import { warnDependent } from '@m365-admin/utilities';
import * as React from 'react';

import type { INavLink } from '../nav/nav.types';
import { NavLink } from '../nav-link/nav-link';
import { NavLinkGroup } from '../nav-link-group/nav-link-group';
import { NavTeachingBubble } from '../nav-teaching-bubble/nav-teaching-bubble';
import type {
  INavGroupProps,
  INavGroupState,
  INavGroupStyleProps,
  INavGroupStyles,
} from './nav-group.types';

const getClassNames = classNamesFunction<INavGroupStyleProps, INavGroupStyles>();

export class NavGroupBase extends React.Component<INavGroupProps, INavGroupState> {
  constructor(props: INavGroupProps) {
    super(props);
    this.state = {
      linkRef: null,
    };
  }
  /* groupId for accessibility linking headers */
  private _groupId: string;

  public componentDidMount() {
    this._groupId = `GroupId__${getId()}`;
  }

  public render(): JSX.Element {
    const {
      groupIndex,
      onRenderGroupTitle,
      links,
      isNavCollapsed,
      onCollapse,
      navRef,
      focusZoneRef,
      styles,
      theme,
      showHeaderTeachingBubble,
      headerTeachingBubbleProps,
      itemStartIndex,
      itemTotal,
    } = this.props;
    const groupTitleProps = this.props.collapsibleGroupTitleProps;

    const classNames = getClassNames(styles, {
      isNavCollapsed: !!isNavCollapsed,
      isCollapsible: !!groupTitleProps,
      theme: theme!,
    });

    const defaultOnRenderGroupTitle = (props?: INavGroupProps): JSX.Element | null => {
      if (
        props?.groupName &&
        (!props.isNavCollapsed || props.collapsibleGroupTitleProps)
      ) {
        return (
          <span
            className={classNames.navGroupTitle}
            role="presentation"
            id={this._groupId}
          >
            {props.groupName}
          </span>
        );
      }

      return null;
    };

    const groupTitle =
      onRenderGroupTitle?.(this.props, defaultOnRenderGroupTitle) ??
      defaultOnRenderGroupTitle(this.props);

    return (
      <>
        {groupIndex !== 0 && (
          <li role="presentation" className={classNames.navGroupDivider} />
        )}
        <div role="group" aria-labelledby={this._groupId}>
          {groupTitleProps ? (
            <NavLinkGroup
              link={{
                name: groupTitle,
                icon: groupTitleProps?.icon,
                links: links,
                ...groupTitleProps?.collapsibleGroupTitleAttributes,
                tooltipProps: {
                  content: this.props.groupName,
                  ...groupTitleProps?.tooltipProps,
                },
                key: `id_${groupIndex}`,
                showTeachingBubble: showHeaderTeachingBubble,
                teachingBubbleProps: headerTeachingBubbleProps,
              }}
              isNavCollapsed={isNavCollapsed}
              isExpanded={groupTitleProps?.isExpanded}
              defaultIsExpanded={groupTitleProps?.defaultExpanded}
              navRef={navRef}
              focusZoneRef={focusZoneRef}
              itemStartIndex={itemStartIndex}
              itemTotal={itemTotal}
              isCollapsibleSection={true}
              isGroupSectionHeader={true}
              styles={styles}
            />
          ) : (
            <>
              {groupTitle && (
                <>
                  <li
                    role="presentation"
                    {...this.props.groupTitleAttributes}
                    ref={this._setLinkRef}
                  >
                    {groupTitle}
                  </li>
                  {showHeaderTeachingBubble && (
                    <NavTeachingBubble
                      target={this.state.linkRef}
                      {...headerTeachingBubbleProps}
                    />
                  )}
                </>
              )}
              {links.map((link: INavLink, index: number) => {
                // warn if developer passes in onExpanded without dependent links prop
                warnDependent('INavLink', link, { onExpanded: 'links' });
                const hasSelectedNestedLink = this._isNestedLinkSelected(link);
                const setSize = itemStartIndex + index;

                return (
                  // if there are nested links, render a NavLinkGroup, otherwise just render a NavLink
                  <li className={classNames.navItem} role="presentation" key={link.key}>
                    {link.links ? (
                      <NavLinkGroup
                        {...link}
                        styles={link.navLinkGroupStyles}
                        isNavCollapsed={isNavCollapsed}
                        link={link}
                        hasNestedMenu={true}
                        hasSelectedNestedLink={hasSelectedNestedLink}
                        onCollapse={onCollapse}
                        navRef={navRef}
                        focusZoneRef={focusZoneRef}
                        itemStartIndex={setSize}
                        itemTotal={itemTotal}
                      />
                    ) : (
                      <NavLink
                        item={link}
                        primaryIconName={link.icon}
                        role="menuitem"
                        {...link}
                        isNavCollapsed={isNavCollapsed}
                        {...(link.isSelected && { 'aria-current': 'page' })}
                        hasNestedMenu={false}
                        isNested={false}
                        aria-posinset={setSize}
                        aria-setsize={itemTotal}
                      />
                    )}
                  </li>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  }

  private _isNestedLinkSelected(link: INavLink): boolean {
    return (
      link &&
      !!link.links &&
      link.links.some((childLink: INavLink) => {
        return !!childLink && !!childLink.isSelected;
      })
    );
  }

  private _setLinkRef = (linkItem: HTMLLIElement | HTMLButtonElement | null): void => {
    // we do this with state because getting a ref is not synchronous
    // without this, a TeachingBubble will not show if showHeaderTeachingBubble is true initially
    if (this.state.linkRef !== linkItem) {
      this.setState({ linkRef: linkItem });
    }
  };
}
