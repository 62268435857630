import { styled } from '@fluentui/react';
import type { FunctionComponent } from 'react';

import { NavBase } from './nav.base';
import { getNavStyles } from './nav.style';
import type { INavProps, INavStyleProps, INavStyles } from './nav.types';

export const Nav: FunctionComponent<INavProps> = styled<
  INavProps,
  INavStyleProps,
  INavStyles
>(NavBase, getNavStyles, undefined, {
  scope: 'M365LeftNav',
  // Note the slightly different scope name from component name
  // This one will directly conflict with Fluent Nav otherwise
});
