import type { IStyle, ITheme } from '@fluentui/react';
import { FontSizes, ZIndexes } from '@fluentui/react';
import { throwOnUndefinedColor } from '@m365-admin/customizations';
import { insertScrollWidthAsCSSVar } from '@m365-admin/scroll-bar-width';

import type { INavStyleProps, INavStyles } from './nav.types';

/**
 * Insert CSS var and detect screen res changes. Only needs to be called once
 */
insertScrollWidthAsCSSVar();

const navDividerHeight = 16;

const componentName = 'Nav';

const navFontSize = FontSizes.medium;
const navWidth = 280;

export const navCollapsedWidth = 48;

export const navItemHeight = 40;

export const navGroupDividerStyle = (theme: ITheme): IStyle => {
  return {
    display: 'block',
    position: 'relative',
    height: navDividerHeight,
    textAlign: 'center',
    '::after': {
      content: '" "',
      width: 'calc(100% - 32px)',
      position: 'absolute',
      height: '1px',
      top: 10,
      insetInlineStart: '16px',
      backgroundColor: theme.palette.neutralTertiaryAlt,
    },
  };
};

export const getNavStyles = (props: INavStyleProps): INavStyles => {
  const { isNavCollapsed, shouldScroll, theme, colorThrowContext } = props;

  return {
    root: {
      position: 'relative',
      zIndex: ZIndexes.Nav,
      flex: '0 0 auto',
    },
    navWrapper: [
      {
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      shouldScroll && {
        ':hover': {
          overflow: 'unset',
        },
      },
    ],
    navContainer: [
      {
        width: `calc(${navWidth}px + var(--scrollbar-width))`,
        backgroundColor: throwOnUndefinedColor(
          theme.semanticColors.navBackground,
          'navBackground',
          componentName,
          { context: colorThrowContext, theme },
        ),
        color: theme.semanticColors.buttonText,
        transitionProperty: 'width',
        transitionDuration: '.2s',
        userSelect: 'none',
        fontSize: navFontSize,
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginInlineEnd: 'calc(var(--scrollbar-width) * -1)',
        height: '100%',
        maxHeight: '100%',
      },
      isNavCollapsed && {
        width: `calc(var(--scrollbar-width) + ${navCollapsedWidth}px)`,
      },
    ],
    navGroup: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    navGroupDivider: navGroupDividerStyle(theme),
    subComponentStyles: {
      collapseNavButtonStyles: {
        root: {
          backgroundColor: throwOnUndefinedColor(
            theme.semanticColors.navBackground,
            'navBackground',
            componentName,
            { context: colorThrowContext, theme },
          ),
        },
      },
    },
  };
};
